import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SurveyorService } from 'src/app/services/SurveyorService/surveyor.service';

@Component({
  selector: 'app-bad-feedback',
  animations: [
    trigger('borderChange', [
      state('off', style({
        border: '2px solid #00000000',
        background: 'white',
        color: 'black'
      })),
      state('on', style({
        border: '2px solid #6255ba',
        background: '#8777d9',
        color: 'white'
      })),
      state('onGood', style({
        border: '2px solid #4aaf85',
        background: '#57d9a3',
        color: 'white'
      })),
      transition('off => on', [
        animate('0.3s')
      ]),
      transition('on => off', [
        animate('0.3s')
      ]),
      transition('off => onGood', [
        animate('0.3s')
      ]),
      transition('onGood => off', [
        animate('0.3s')
      ]),
    ]),

  ],
  templateUrl: './bad-feedback.component.html',
  styleUrls: ['./bad-feedback.component.css']
})
export class BadFeedbackComponent implements OnInit {
  reasons = [
    'Uncooperative Behaviour',
    'Incomplete Information',
  ]
  selectionControl: FormControl = new FormControl('')
  commentControl: FormControl = new FormControl('')
  badFeedback: boolean = false;
  textDisplay = 'We are sorry to hear about your experience with us.';
  urlParams: any = {
    claimID: '',
    requestID: '',
    isGoodFeedback: 0,
    encClaimID: ''
  };
  feedbackData: any;
  feedbackText = 'Tell us more about what did you like the most?'
  constructor(private activatedRouter: ActivatedRoute, private surveyorService: SurveyorService) { }

  ngOnInit(): void {
    this.urlParams.claimID = atob(this.activatedRouter.snapshot.paramMap.get('claimID'));
    this.urlParams.isFeedbackType = this.urlParams.claimID.split(':')[this.urlParams.claimID.split(':').length - 1];
    // this.urlParams.isFeedbackType = 3;
    this.badFeedback = this.urlParams.isFeedbackType == 1 ? false : true;
    this.urlParams.encClaimID = this.urlParams.claimID.split(':')[0];
    this.urlParams.requestID = atob(this.activatedRouter.snapshot.paramMap.get('requestID'));
    this.changeMessage();
    this.initFeedbackSubmit(true);
  }

  selectReason(reason) {
    this.selectionControl.patchValue(reason)
  }

  changeMessage() {
    if(this.urlParams.isFeedbackType == 1) {
      this.textDisplay = 'Thank you! We look forward to serving you again.'
      this.feedbackText = 'Tell us more about what did you like the most?'
    }
    else {
      this.textDisplay = 'We are sorry to hear about your experience with us.'
      this.feedbackText = 'What went wrong in our assistance?'
    }
  }

  initFeedbackSubmit(init = false) {
    let payload = {
      "AgentRating": this.urlParams.isFeedbackType,  // 1 For Good and 3 For Bad
      "IsSubmit": true,
      "CreatedBy": 124,
      "EncClaimID": this.urlParams.encClaimID,
      "FeedbackRequestId": this.urlParams.requestID,
      "IsV2Version": true,
      "FeedbackQuesAns": [
        {
            "Question": "How was your experience?",
            "InputType": "Feedback",
            "Response": this.urlParams.isFeedbackType == 1 ? 'Good' : 'Bad'
        }
      ],
    }
    if(this.urlParams.isFeedbackType == 1 && !init) {
      let pushObj =         {
        "Question": "Comments",
        "InputType": "Text",
        "Response": this.commentControl.value
    }
      payload.FeedbackQuesAns.push(pushObj);
    }
    else if(this.urlParams.isFeedbackType == 3 && !init) {
      let pushObjReasons =         {
        "Question": "What went wrong in our assistance?",
        "InputType": "Selection",
        "Response": this.selectionControl.value
      }
      payload.FeedbackQuesAns.push(pushObjReasons);
      let pushObjComments =  {
        "Question": "Comments",
        "InputType": "Text",
        "Response": this.commentControl.value
      }
      payload.FeedbackQuesAns.push(pushObjComments);
    }
    this.surveyorService.setCustomerFeedback(payload).subscribe((data: any) => {
      if(data.Data != null) {
        if(init) {
          this.getCustomerFeedback();
        }
        if(!init) {
          this.feedbackData.FeedbackType = '';
        }
      }
    })
  }

  getCustomerFeedback() {
    this.surveyorService.getCustomerFeedback(btoa(this.urlParams.encClaimID)).subscribe((data: any) => {
      this.feedbackData = data.Data[0];
    })
  }

}
