import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { LoaderComponent } from './components/shared/loader/loader.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ClaimAssignmentComponent } from './components/claim-assignment/claim-assignment.component';
import { ClaimDetailsComponent } from './components/claim-details/claim-details.component';
import { PendingCasesComponent } from './components/pending-cases/pending-cases.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { BadFeedbackComponent } from './components/bad-feedback/bad-feedback.component';
import { DynamicDigitalFormsComponent } from './components/dynamic-digital-forms/dynamic-digital-forms.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'claim-assignment', component: ClaimAssignmentComponent },
  { path: 'case-details/:claimId', component: ClaimDetailsComponent },
  { path: 'pending-cases', component: PendingCasesComponent },
  { path: 'feedback/:params', component: FeedbackComponent },
  { path: 'loader', component: LoaderComponent},
  { path: 'customerfeedback/:claimID/:requestID', component: BadFeedbackComponent },
  { path: 'dynamic-forms', component: DynamicDigitalFormsComponent, pathMatch: 'full' },
  { path: '**', pathMatch: 'full', component: LoginComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
