import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { SharedService } from './../../services/SharedService/sharedService';
import { SurveyorService } from './../../services/SurveyorService/surveyor.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { saveAs } from 'file-saver';

declare let $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {


  constructor(private sharedService :SharedService, private surveyorService:SurveyorService, private router: Router, private fb: FormBuilder) { }
  LoginDetails: any
  DashboardCount: any;
  IsTAT: any;
  ErrorMsg: string = '';
  ClaimLists: any;
  ClaimListsRaw: any;
  BucketName: string = '';
  IsNew: any;
  OpenDashboardCount: any;
  RefIdText: string = 'PB';
  dashboardSeachForm!: FormGroup;
  intimationDate = '';
  tableSearchControl: FormControl = new FormControl('')

  dashboardTableLabels = ['PB Reference ID', 'Policy Holder', 'Policy No./link', 'Registration No.', 'City', 'State', 'Assigned Garage', 'Case Age (Days)', 'Case Age Insurer', 'Surveyor', 'Officer', 'Request Type', 'Insurer Claim ID']
  dashboardTableColumns = ['ClaimId', 'PolicyHolderName', 'PolicyNumber', 'RegistrationNo', 'City', 'State', 'GarageName', 'CaseAge', 'CaseAgeInsurer', 'Surveyor', 'Officer', 'RequestType', 'InsurerClaimId']
  exportData: any;
  ngOnInit(): void {
    this.dashboardSeachForm = this.fb.group({
      PolicyNo: [''],
      RegistrationNo: [''],
      PBClaimID: [''],
      start: new FormControl(new Date()),
      end: new FormControl(new Date()),
      IntimationDate: ['']
    })
    this.sharedService.userDetailsSubject$.subscribe(data => {
      if(typeof data == 'string'){
        this.LoginDetails = JSON.parse(data);
      }
      else{
        this.LoginDetails = data;
      }
    });

    if(Object.keys(this.LoginDetails).length > 0){
      if(this.router.url == '/dashboard'){
        this.GetDashboard();
        this.GetDashboardCount();
        this.GetInsurerOpenClaimsCount();
      }

      if(this.router.url == '/pending-cases'){
        this.GetDashboard();
        this.GetDashboardCount();
        //this.GetInsurerOpenClaimsCount();
      }


    }else{
      this.router.navigate(['/login'])
    }

    this.tableSearchControl.valueChanges.subscribe(changes => {
      this.ClaimLists = this.ClaimListsRaw.filter((item: any) => {
        if(Object.values(item).toString().replaceAll(',', ' ').toLowerCase().includes(changes.toLowerCase())) {
          return item;
        }
      })
    })

  }

  ngOnChanges(): void{
    this.sharedService.userDetailsSubject$.subscribe(data => {
      if(typeof data == 'string'){
        this.LoginDetails = JSON.parse(data);
      }
      else{
        this.LoginDetails = data;
      }
    });

    if(Object.keys(this.LoginDetails).length > 0){
      if(this.router.url == '/dashboard'){
        this.GetDashboard();
        this.GetDashboardCount();
        this.GetInsurerOpenClaimsCount();
      }

      if(this.router.url == '/pending-cases'){
        this.GetDashboard();
        this.GetDashboardCount();
        //this.GetInsurerOpenClaimsCount();
      }


    }else{
      this.router.navigate(['/login'])
    }

  }

  GetDashboard() {
    //this.val: any = $location.hash();
    let params = '';
    if (params == "TAT") {
        let bucketData = {
          bucketId: 0,
          bucketName: 'TAT Burst Cases'
        }
        this.GetInsurerDashboardDetails(bucketData);
        this.IsTAT = 1;
    }
    else {
        this.IsTAT = 0;
    }
  }

  GetDashboardCount() {
    //this.ClaimLists = [];
    //this.ErrorMsg = "";
    //this.AgentAssined = 0;
    if(this.router.url == '/dashboard'){
      let reqData = this.LoginDetails.InsurerId + "/" + this.LoginDetails.UserType + "/" + this.LoginDetails.ProductID + "/" + this.LoginDetails.UserId;
      this.surveyorService.getDashboardCount(reqData).subscribe((res)=>{
        if(res){
          this.ErrorMsg = ''
          this.DashboardCount = res.Data
          if(this.IsTAT==0){
            let bucketData = {
              bucketId : this.DashboardCount[0].BucketID,
              bucketName: this.DashboardCount[0].BucketName
            }
            this.GetInsurerDashboardDetails(bucketData);
          }
          else{
            this.ErrorMsg = 'Error: Something Went Wrong!';
          }
        }
        else{
          this.ErrorMsg = 'Error: Something Went Wrong!';
        }
      })
    }
    else if(this.router.url == '/pending-cases'){
      this.ClaimLists = [];
      this.ClaimListsRaw = [];
      let reqData = this.LoginDetails.UserType + "/" + this.LoginDetails.UserId;

      this.surveyorService.getPendingDashboardCount(reqData).subscribe((res)=>{
        if(res.Data){
          this.DashboardCount = res.Data
          let bucketData = {
            bucketName: this.DashboardCount[0].BucketName,
            subRequestType: this.DashboardCount[0].SubRequestType
          }
          this.GetInsurerDashboardDetails(bucketData)
        }
      })
    }

  }

  GetInsurerDashboardDetails(bucketData: any, requestType = 0){
      if(this.router.url == '/dashboard'){
        this.ClaimLists = [];
        this.ClaimListsRaw = [];
        this.BucketName = bucketData.bucketName;
        this.IsNew = false;
        this.ErrorMsg = "";
        bucketData.bucketId=bucketData.bucketId && bucketData.bucketId!="undefined"?bucketData.bucketId:0;

        let reqData = `${this.LoginDetails.InsurerId }/${bucketData.bucketId}/${this.LoginDetails.ProductID}/${this.LoginDetails.UserId}/${this.LoginDetails.UserType}`;

        this.surveyorService.GetInsurerDashboardDetails(reqData).subscribe((res)=>{
          if(res && res.Data){
            //this.ClaimLists = res.Data
            if (bucketData.bucketId == 0) {
              res.Data.forEach((element: any) => {
                if(element.CaseAge > 15){
                  this.ClaimLists.push(element)
                }
              });
            } else {
                this.ClaimLists = res.Data;
                this.ClaimListsRaw = res.Data;
            }
          }
          else{
            this.ErrorMsg = "Error: Something Went Wrong!"
          }
        })
      }
      else if(this.router.url == '/pending-cases'){
        this.ClaimLists = [];
        this.ClaimListsRaw = [];
        this.ErrorMsg = "";
        let isDo = false;
        if( bucketData.bucketName == 'Delivery Order Awaited (Cashless)') {
            isDo = true;
        }
        this.BucketName = bucketData.bucketName;
        let reqData = `${this.LoginDetails.UserId}/${this.LoginDetails.UserType}/${bucketData.subRequestType ? bucketData.subRequestType : requestType}/${isDo}`;

        this.surveyorService.GetInsurerStatusDashboardDetails(reqData).subscribe((res)=>{
          if(res && res.Data){
            //this.ClaimLists = res.Data
            if (bucketData.bucketId == 0) {
              res.Data.forEach((element: any) => {
                if(element.CaseAge > 15){
                  this.ClaimLists.push(element)
                }
              });
            } else {
                this.ClaimLists = res.Data;
                this.ClaimListsRaw = res.Data;
            }
          }
          else{
            this.ErrorMsg = "Error: Something Went Wrong!"
          }
        })
      }
      else{
        this.ClaimLists = [];
        this.ClaimListsRaw = [];
      }


  }

  GetInsurerDashboardDetailsOnClick(bucketId:any,bucketName: any, requestType = 0){
    let bucketData = {
      bucketId: bucketId,
      bucketName: bucketName
    }
    this.GetInsurerDashboardDetails(bucketData, requestType)
  }

  GetInsurerOpenClaimDashboard(bucketId: any, OpenBucketName: any) {
    this.ClaimLists = [];
    this.ClaimListsRaw = [];
    this.BucketName = OpenBucketName;
    this.IsNew = false;
    this.ErrorMsg = "";
    let reqData = `${this.LoginDetails.InsurerId }/${bucketId}/${this.LoginDetails.ProductID}/${this.LoginDetails.UserId}/${this.LoginDetails.UserType}`;


    this.surveyorService.GetInsurerOpenClaimDashboard(reqData).subscribe((res)=>{
      if(res.Data){
          if (bucketId == 0) {
            res.Data.forEach((element: any) => {
              if(element.CaseAge > 15){
                this.ClaimLists.push(element)
              }
            });
          } else {
              this.ClaimLists = res.Data;
              this.ClaimListsRaw = res.Data;
          }
      }
      else{
        this.ErrorMsg = "Error: Something Went Wrong!"
      }
    })
  }

  GetInsurerOpenClaimsCount(){
    this.ClaimLists = [];
    this.ClaimListsRaw = [];
    this.ErrorMsg = "";

    let reqData = `${this.LoginDetails.InsurerId}/${this.LoginDetails.UserType}/${this.LoginDetails.ProductID}/${this.LoginDetails.UserId}`;
    this.surveyorService.GetInsurerOpenClaimsCount(reqData).subscribe((res)=>{
      if (res && res.Data) {
        this.OpenDashboardCount = res.Data;
        if (this.IsTAT == 0) {

            setTimeout(() => {
              this.GetInsurerOpenClaimDashboard(-1, this.OpenDashboardCount.BucketName);
            }, 3000);

            // window.setTimeout(function () {
            //     this.GetInsurerOpenClaimDashboard(-1, this.OpenDashboardCount.BucketName);
            // }, 3000);
        }
    } else {
        this.ErrorMsg = "Error : Something went wrong!";
    }
    })

  }

  //exportToExcel(Id: any){
    // var exportHref = Excel.tableToExcel(Id, 'DashBoardDataExport');
    //         $timeout(function () { location.href = exportHref; }, 5000); // trigger download
  //}

  Case_Details(ClaimId: any, EncClaimId: any){
    this.sharedService.setEncClaimId(EncClaimId)
    localStorage.setItem('ECID', EncClaimId)
    this.router.navigate(['/case-details', btoa(`${ClaimId}$${EncClaimId}`)]);
  }

  GetSearchResult(){
    this.BucketName = 'Search Result'
    this.ClaimLists = [];
    this.ClaimListsRaw = [];
    console.log(`this.dashboardSeachForm.controls['IntimationDate']: ${this.dashboardSeachForm.controls['IntimationDate'].value}`)

    let reqData = {
      PolicyNo: this.dashboardSeachForm.controls['PolicyNo'].value,
      RegistrationNo: this.dashboardSeachForm.controls['RegistrationNo'].value,
      // FromDate: this.dashboardSeachForm.controls['start'].value.toLocaleDateString(),
      // ToDate: this.dashboardSeachForm.controls['end'].value.toLocaleDateString(),
      FromDate: moment(this.dashboardSeachForm.controls['start'].value).format("MM/DD/YYYY"),
      ToDate: moment(this.dashboardSeachForm.controls['end'].value).format("MM/DD/YYYY"),
      ProductID: this.LoginDetails.ProductID,
      InsurerID: this.LoginDetails.InsurerId,
      UserId: this.LoginDetails.UserId,
      UserType: this.LoginDetails.UserType,
      ClaimID: this.dashboardSeachForm.controls['PBClaimID'].value.split('PB').length > 1 ? this.dashboardSeachForm.controls['PBClaimID'].value.split('PB')[1] : this.dashboardSeachForm.controls['PBClaimID'].value.split('PB')[0]
  };

    this.surveyorService.GetInsurerDashboardBySearch(reqData).subscribe((res: any)=>{
      if(res.Data){
        this.ClaimLists = res.Data;
        this.ClaimListsRaw = res.Data;
      }else{
        this.ClaimLists = [];
        this.ClaimListsRaw = [];
        alert("No Record Found!!")
      }
    })
  }

  dateChange(event: any){
    console.log(`event: ${event}`)
  }

  exportToExcelNew() {
    saveAs(this.exportData, 'table-data.xlsx');
  }

  exportToExcel(){
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const downloadExcel = (csvData: any, fileName: any) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    if(this.ClaimLists.length>0){
      downloadExcel(this.ClaimLists, 'DashBoardDataExport')
    }
  }

  storeExportTable(event) {
    this.exportData = event;
  }

  openStatusPage(event) {
    this.Case_Details(event.ClaimId, event.EncClaimId)
  } 
}
